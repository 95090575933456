<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <avatars-list></avatars-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AvatarsList from '../components/AvatarsList';

export default {
  name: 'avatars-page',
  components: {
    AvatarsList
  }
};
</script>
